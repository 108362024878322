import "vuetify/styles"
import "material-design-icons-iconfont/dist/material-design-icons.css"
import { createVuetify } from "vuetify"
import { aliases, md } from "vuetify/iconsets/md"
import { colors } from "@/config"

const springcastTheme = {
    dark: false,
    colors: colors,
}

const vuetify = createVuetify({
    defaults: {
        global: {
            ripple: false,
        },
        VCardText: {
            style: "padding: 1.5rem",
        },
        VDivider: {
            style: "opacity: 1",
        },
        VBtn: {
            style: "text-transform: none",
        },
        VToolbar: {
            elevation: 0,
            style: "border-bottom: 1px solid rgba(var(--v-border-color), var(--v-border-opacity))",
        },
    },
    icons: {
        defaultSet: "md",
        aliases,
        sets: {
            md,
        },
    },
    theme: {
        cspNonce: document.querySelector('meta[property="csp-nonce"]').content,
        defaultTheme: "springcast",
        themes: {
            springcast: springcastTheme,
        },
    },
})

export default vuetify
