export const colors = {
    background: "#F5F5F3",
    surface: "#ffffff",
    theme: "#12055A",
    primary: "#76C4B8",
    secondary: "#12055A",
    dark_blue: "#12055A",
    dark_purple: "#512B9F",
    pink: "#EB5AA9",
    salmon: "#F3B3C5",
    theme_error: "rgb(240,68,56)",
    error: "#F04438",
    info: "#2196F3",
    success: "#12B76A",
    warning: "#F79009",
    trial_warning: "#FEB273",
    trial_error: "#F03859",
    trial_expired: "#f1e3db",
    sc_grey: "#f3f3f3",
    spotify: "#1DB954",
}

export const tailwindColors = {
    ...colors,
    sc: {
        green: "#76C4B8",
        pink: "#EB5AA9",
        salmon: "#F3B3C5",
        "dark-blue": "#12055A",
        "dark-purple": "#512B9F",
        purple: "#A42ED0",
        grey: "#f3f3f3",
        "black-purple": "#270836",
        "gradient-purple": "linear-gradient(93deg, #512B9F 6.55%, #733ABB 96.21%)",
    },
    spotify: "#1DB954",
    blue: {
        25: "#F5FAFF",
        50: "#EFF8FF",
        100: "#D1E9FF",
        200: "#B2DDFF",
        300: "#84CAFF",
        400: "#53B1FD",
        500: "#2E90FA",
        600: "#1570EF",
        700: "#175CD3",
        800: "#1849A9",
        900: "#194185",
    },
    grey: {
        25: "#FCFCFD",
        50: "#F9FAFB",
        100: "#F2F4F7",
        200: "#E4E7EC",
        300: "#D0D5DD",
        400: "#98A2B3",
        500: "#667085",
        600: "#475467",
        700: "#344054",
        800: "#1D2939",
        900: "#101828",
    },
    "grey-stone": {
        200: "#E7E5E4",
    },
    warning: {
        800: "#93370D",
        300: "#FEC84B",
        200: "#FEB273",
    },
    error: {
        400: "#F03859",
    },
}
